import { template as template_cb9d31a9856b4e34a7387d5348aecc18 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cb9d31a9856b4e34a7387d5348aecc18(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
