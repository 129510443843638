import { template as template_8642cf77d7e648dd9de35395a6033a67 } from "@ember/template-compiler";
const FKLabel = template_8642cf77d7e648dd9de35395a6033a67(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
