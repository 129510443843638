import { template as template_95c9800ffc4a47818166547ac1191f6e } from "@ember/template-compiler";
const FKText = template_95c9800ffc4a47818166547ac1191f6e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
